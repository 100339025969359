/* eslint-disable */
// eslint-disable-next-line no-use-before-define
import React, { memo, useState, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
import {
  TooltipTypography,
  DatePicker,
  ConfirmationPopup,
  Progress,
} from "../generic";
import { Save, Edit, NotiOn, NotiOff } from "../../images";
import {
  saveAvailabilityInformation,
  updateAvailabilityLocalChanges,
  subscribeStockNotification,
  deleteNotificationSubscription,
  NotificationType,
  fetchSubscribedNotificationsPerCustomer,
} from "../../redux/reducers";
import {
  getFormattedDateTime,
  isDateLessThanDayAgo,
} from "../../utils/date/date";
import { getCustomerId } from "../../utils/customer/customer";
import { Permission } from "../auth";
import styles from "./tableStyles";
import { EcomUrls } from "../../utils/navigation/navigation";
import {
  addOrRemoveItemsToArray,
  shouldDisableStockNotificationConfirmButton,
  getExistingNotificationsStatus,
  prepareNotificationIdsToDelete,
  prepareUniqueEmailsForStockNotifications,
} from "../../utils/stockNotificationUtil";
import { getVatInfo } from "../../utils/vat/vat.util";
import CheckboxItem from "../generic/checkboxItem/checkboxItem";
import { renderEstimatedAvailability } from "./utils";

const useStyles = makeStyles(styles);

export const getLastUpdateUserName = availability => {
  return availability.companies[0].deliveryBlockCode === "Z7"
    ? "SAP"
    : availability.latestUpdate.user.name;
};

function ProductManagementTableRow({
  availability,
  notifications,
  customers,
  isHiglighted,
  isEditing,
  permission,
  price,
  showAvailabilityLabels,
  showEstimationInWeeks,
  profitCenterName,
  marketerName,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const tEN = i18n.getFixedT("en");
  const tFI = i18n.getFixedT("fi");

  const dispatch = useDispatch();
  const userData = useSelector(state => state.user.userData);
  const { sccInformation } = useSelector(state => state.scc);
  const [editingMode, setEditingMode] = useState(isEditing);
  const [information, setInformation] = useState(null);
  const [localChanges, setLocalChanges] = useState(availability.localChanges);
  const [isInformationPermanent, setIsInformationPermanent] = useState(
    localChanges?.isInformationPermanent ?? availability.hasPermanentInformation
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [availabilityDate, setAvailabilityDate] = useState(
    // eslint-disable-next-line no-nested-ternary
    localChanges && localChanges.availabilityDate
      ? moment(localChanges.availabilityDate).toDate()
      : availability.availabilityDate
        ? moment(availability.availabilityDate).toDate()
        : null
  );
  const { fetchingPrices } = useSelector(state => state.price);
  const [showPopup, setShowPopup] = useState(false);
  // Store separate emails for subscription or unsubscription
  const [emailsForStockNotification, setEmailsForStockNotification] = useState({
    subscription: [],
    unsubscription: [],
  });
  const [checkBoxStatus, setCheckBoxStatus] = useState({});
  const [originalCheckBoxStatus, setOriginalCheckboxStatus] = useState({});
  const { selectedCustomerId } = useSelector(state => state.customer);
  // Prepare unique emails for stock notification (personal & pharmacy emails if available)
  const emails = prepareUniqueEmailsForStockNotifications(
    userData,
    customers,
    selectedCustomerId
  );

  useEffect(() => {
    setCheckBoxStatus(
      getExistingNotificationsStatus(notifications, availability.productId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, setCheckBoxStatus]);

  useEffect(() => {
    setOriginalCheckboxStatus(
      getExistingNotificationsStatus(notifications, availability.productId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, setOriginalCheckboxStatus]);

  const productName = ProductUtil.getLocalizedProductName(
    availability,
    i18n.language
  );
  const contentAmount = ProductUtil.getProductContentAmount(
    availability,
    i18n.language
  );

  let productNameWithAmount = "-";
  if (productName != null) {
    productNameWithAmount = `${productName} ${contentAmount}`;
  }
  const { productStrength } = availability;

  const estimatedAvailability = renderEstimatedAvailability(
    availability,
    showEstimationInWeeks,
    t
  );

  let vnrMsi = null;
  if (availability.vnr) {
    vnrMsi = `${t("vnr")}: ${availability.vnr} | `;
  } else if (availability.msiCode) {
    vnrMsi = `${t("msi")}: ${availability.msiCode} | `;
  }

  const productIdText = `${t("productManagementTable.productNumber")}: ${
    availability.productId
  } | `;

  const vatInfo = getVatInfo(price, fetchingPrices, t);

  const marketer = marketerName
    ? `${t("productManagementTable.marketer")}: ${marketerName}`
    : "";

  const newAvailabilityNeeded =
    availability.availabilityEstimationExpired || false;
  const waitingActions =
    (!availability.availabilityDate || !availability.information) &&
    !newAvailabilityNeeded;

  const handleEdit = () => setEditingMode(true);
  const getScc = () => {
    const info = sccInformation.filter(x =>
      x.profitCenters.includes(availability.profitCenter)
    );
    return (
      <>
        <Typography className={classes.primaryText} variant="body2">
          {profitCenterName}
        </Typography>
        {info.length > 0 ? (
          <Typography className={classes.secondaryText}>
            {info.map(x => `${x.givenName} ${x.familyName}`).join(", ")}
          </Typography>
        ) : (
          <Typography className={classes.secondaryText}>-</Typography>
        )}
      </>
    );
  };

  const refreshEditingMode = () => {
    // Set editing mode true if local changes differ from data stored to availability.
    if (
      localChanges &&
      !editingMode &&
      !(
        ((!localChanges.availabilityDate && !availability.availabilityDate) ||
          moment(localChanges.availabilityDate).isSame(
            availability.availabilityDate
          )) &&
        localChanges.information === availability.information
      )
    ) {
      setEditingMode(true);
    } else {
      setEditingMode(isEditing);
    }
  };

  const updateLocalChanges = async (updatedStatus, updatedAvailability) => {
    setLocalChanges(updatedStatus);
    await dispatch(
      updateAvailabilityLocalChanges(
        updatedAvailability.productId,
        updatedStatus
      )
    );
  };

  const handleLocalChange = (
    information,
    availabilityDate,
    isInformationPermanent
  ) => {
    const updatedStatus = {
      information,
      availabilityDate,
      isInformationPermanent,
    };
    updateLocalChanges(updatedStatus, availability);
  };

  const equalsHandleEmpty = (s1, s2) => (s1 || "") === (s2 || "");
  const getDateStr = s => (s && new Date(s).toISOString()) || "";

  const hasChanged = () => {
    if (availability.hasPermanentInformation !== isInformationPermanent) {
      return true;
    }

    if (!equalsHandleEmpty(availability.information, information)) {
      return true;
    }

    const oldDate = getDateStr(availability.availabilityDate);
    const newDate = getDateStr(availabilityDate);

    if (!equalsHandleEmpty(oldDate, newDate)) {
      return true;
    }

    return false;
  };

  const handleSave = async () => {
    if (!hasChanged()) {
      setEditingMode(isEditing);
      return;
    }
    const latestUpdate = { user: { name: userData.name, id: userData.userId } };
    const hasPermanentInformation = information
      ? isInformationPermanent
      : false;

    const availabilityInfo = {
      ...availability,
      information,
      availabilityDate,
      hasPermanentInformation,
      latestUpdate,
      availabilityEstimationExpired: false,
    };
    setIsUpdating(true);
    const updatedAvailability = await dispatch(
      saveAvailabilityInformation(availabilityInfo)
    );
    setIsUpdating(false);
    updateLocalChanges(
      {
        information,
        availabilityDate,
        isInformationPermanent: hasPermanentInformation,
      },
      updatedAvailability
    );
    if (isInformationPermanent !== hasPermanentInformation) {
      setIsInformationPermanent(hasPermanentInformation);
    }
    setEditingMode(isEditing);
  };

  const cleanInformation = info => {
    return info
      .replace(tEN("orderByDemand"), "")
      .replace(tFI("orderByDemand"), "");
  };
  useEffect(() => {
    if (!information) {
      let tempInformation =
        localChanges?.information || availability.information || "";

      if (availability.companies[0].deliveryBlockCode === "Z7") {
        tempInformation = `${t(
          "orderByDemand"
        )} ${cleanInformation(tempInformation)}`;
      }
      setInformation(tempInformation);
    }
    if (!localChanges) {
      const updatedStatus = {
        information: availability.information,
        availabilityDate: availability.availabilityDate,
        isInformationPermanent: availability.hasPermanentInformation,
      };
      updateLocalChanges(updatedStatus, availability);
    }
    refreshEditingMode();
  }, [
    availability.information,
    availability.availabilityDate,
    availability.hasPermanentInformation,
  ]); // eslint-disable-line

  const showNotificationPopup = () => {
    setShowPopup(true);
  };

  const handleChange = event => {
    const { value, checked } = event.target;
    if (checked) {
      setEmailsForStockNotification({
        subscription: addOrRemoveItemsToArray(
          emailsForStockNotification.subscription,
          value,
          "add-subscription",
          notifications,
          availability.productId
        ),
        unsubscription: addOrRemoveItemsToArray(
          emailsForStockNotification.unsubscription,
          value
        ),
      });
      setCheckBoxStatus({
        ...checkBoxStatus,
        [value]: checked,
      });
    } else {
      setEmailsForStockNotification({
        subscription: addOrRemoveItemsToArray(
          emailsForStockNotification.subscription,
          value
        ),
        unsubscription: addOrRemoveItemsToArray(
          emailsForStockNotification.unsubscription,
          value,
          "add-unsubscription"
        ),
      });
      const newCheckboxStatus = { ...checkBoxStatus };
      delete newCheckboxStatus[value];
      setCheckBoxStatus(newCheckboxStatus);
    }
  };

  const subscribeEmail = () => {
    dispatch(
      subscribeStockNotification({
        userId: userData.userId,
        customerId: getCustomerId(customers),
        notificationType: NotificationType.EMAIL,
        productId: availability.productId,
        productName,
        productPackageSize: contentAmount,
        productStrength,
        productVnrOrMsiCode:
          (availability.vnr ? availability.vnr : availability.msiCode) ?? "",
        siteUrl: `${EcomUrls.Products}/${availability.productId}?organizationId=${selectedCustomerId}`,
        emails: emailsForStockNotification.subscription,
      })
    ).then(result => {
      if (result) {
        setShowPopup(false);
        setEmailsForStockNotification({ subscription: [], unsubscription: [] });
      }
    });
  };

  const handleNotifyClick = () => {
    const notificationIdsToDelete = prepareNotificationIdsToDelete(
      notifications,
      availability.productId,
      emailsForStockNotification.unsubscription
    );

    if (notificationIdsToDelete.length !== 0) {
      dispatch(
        deleteNotificationSubscription(
          userData.userId,
          notificationIdsToDelete.join(",")
        )
      ).then(result => {
        if (result) {
          if (emailsForStockNotification.subscription.length !== 0) {
            subscribeEmail();
          } else {
            dispatch(
              fetchSubscribedNotificationsPerCustomer(userData.organizationIds)
            );
            setShowPopup(false);
            setEmailsForStockNotification({
              subscription: [],
              unsubscription: [],
            });
          }
        }
      });
    } else if (emailsForStockNotification.subscription.length !== 0) {
      subscribeEmail();
    }
  };

  const renderStockSubscriptionPopup = () => {
    const content = {
      onChange: handleChange,
      contentType: "stockNotification",
      emails,
      checkboxItemsStatus: checkBoxStatus,
      notifications,
      user: userData,
      productId: availability.productId,
    };
    return (
      <ConfirmationPopup
        title={t("stockNotificationPopupTitle")}
        description={t("stockNotificationPopupDesc")}
        iconType="subscription"
        show={showPopup}
        onOk={handleNotifyClick}
        saveKey="confirm"
        onCancel={() => {
          setShowPopup(false);
          setEmailsForStockNotification({
            subscription: [],
            unsubscription: [],
          });
        }}
        disableSave={shouldDisableStockNotificationConfirmButton(
          originalCheckBoxStatus,
          checkBoxStatus
        )}
        content={content}
      />
    );
  };

  const renderNotifyMeButton = () => {
    const notificationSent = notifications.some(
      n => n.email === userData.email && n.productId === availability.productId
    );
    return (
      <div className={clsx(classes.notify, classes.column)}>
        <Button
          className={
            !notificationSent ? classes.secondaryButton : classes.primaryButton
          }
          variant="contained"
          onClick={showNotificationPopup}
          disableElevation
          startIcon={!notificationSent ? <NotiOn /> : <NotiOff />}
        >
          {!notificationSent ? (
            <p>{t("productManagementTable.notify")}</p>
          ) : (
            <p>{t("productManagementTable.cancelNotify")}</p>
          )}
        </Button>
      </div>
    );
  };

  const renderUpdateButtons = () => {
    if (isUpdating) {
      return null;
    }
    return (
      (editingMode && (
        <Button onClick={handleSave} classes={classes.label}>
          <Save /> {t("productManagementTable.update")}
        </Button>
      )) || (
        <Button onClick={handleEdit} classes={classes.label}>
          <Edit /> {t("productManagementTable.edit")}
        </Button>
      )
    );
  };

  const renderAvailabilityDate = () => {
    if (!availability.latestUpdate) {
      return "-";
    }

    return (
      <div className={classes.latestUpdateLabel}>
        <div>
          {t("productManagementTable.updatedBy", {
            name: getLastUpdateUserName(availability),
          })}
        </div>
        <div>{getFormattedDateTime(availability.latestUpdate.time)}</div>
      </div>
    );
  };

  const showUpdatedLabel = () =>
    permission === Permission.MANAGEMENT_PHARMACY &&
    availability.latestUpdate &&
    isDateLessThanDayAgo(availability.latestUpdate.time);

  return (
    <div
      className={clsx(
        classes.tableRow,
        isHiglighted ? classes.highlightRow : classes.higlightOffsetMargin
      )}
    >
      <div className={clsx(classes.product, classes.column)}>
        <a
          className={classes.link}
          href={`${EcomUrls.Products}/${availability.productId}`}
        >
          <TooltipTypography
            className={clsx(classes.ellipsisText, classes.column)}
            variant="body2"
            tooltip={productNameWithAmount}
            noWrap
          >
            {productNameWithAmount}
          </TooltipTypography>
          <Typography
            variant="body2"
            className={clsx(classes.infoText, classes.column)}
          >
            {vnrMsi}
            {productIdText}
            {permission === Permission.MANAGEMENT_PHARMACY && marketer}
          </Typography>
          <Typography
            variant="body2"
            className={clsx(classes.infoText, classes.column)}
          >
            {vatInfo}
          </Typography>
        </a>
      </div>
      {permission === Permission.MANAGEMENT_ORIOLA && (
        <div className={clsx(classes.principal, classes.column)}>
          {getScc()}
        </div>
      )}
      <div className={clsx(classes.estimated, classes.column)}>
        <Typography variant="body2" className={classes.primaryText}>
          {availability.companies[0].deliveryBlockCode !== "Z7" &&
            estimatedAvailability}
        </Typography>

        {showUpdatedLabel() && (
          <div className={classes.updatedLabel}>
            {t("productManagementTable.updated")}
          </div>
        )}
        {newAvailabilityNeeded && showAvailabilityLabels && (
          <div className={classes.newAvailabilityNeededLabel}>
            {t("productManagementTable.missingAvailabilityInfo")}
          </div>
        )}
        {waitingActions && showAvailabilityLabels && (
          <div className={classes.waitingActionsLabel}>
            {t("productManagementTable.waitingForAction")}
          </div>
        )}
      </div>
      {(permission === Permission.MANAGEMENT_ORIOLA ||
        permission === Permission.MANAGEMENT_PHARMACOMPANY) && (
        <div className={clsx(classes.availability, classes.column)}>
          {(editingMode && (
            <DatePicker
              value={availabilityDate}
              onClick={date => {
                const formattedDate = date
                  ? moment(date).utc().startOf("day").toDate()
                  : null;
                setAvailabilityDate(formattedDate);
                handleLocalChange(
                  information,
                  formattedDate,
                  isInformationPermanent
                );
              }}
              showCalendar
              locale={i18n.locale}
              placeholder={t("productManagementTable.selectDate")}
              disabledDays={[
                {
                  before: moment().toDate(),
                },
              ]}
              helperText={t("productManagementTable.invalidDate")}
            />
          )) ||
            renderAvailabilityDate()}
        </div>
      )}
      <div className={clsx(classes.information, classes.column)}>
        {(editingMode && (
          <TextField
            value={information || ""}
            variant="outlined"
            placeholder=""
            className={classes.textField}
            margin="dense"
            onChange={e => {
              setInformation(e.target.value);
              handleLocalChange(
                e.target.value,
                availabilityDate,
                isInformationPermanent
              );
            }}
            inputProps={{ maxLength: 50 }}
          />
        )) ||
          information ||
          "-"}
      </div>
      <div className={clsx(classes.informationPermanent, classes.column)}>
        {(editingMode && (
          <CheckboxItem
            checked={isInformationPermanent}
            onChange={e => {
              setIsInformationPermanent(e.target.checked);
              handleLocalChange(
                information,
                availabilityDate,
                e.target.checked
              );
            }}
          />
        )) ||
          (isInformationPermanent ? t("yes") : "-")}
      </div>
      {permission === Permission.MANAGEMENT_PHARMACY && renderNotifyMeButton()}
      {renderStockSubscriptionPopup()}
      {(permission === Permission.MANAGEMENT_ORIOLA ||
        permission === Permission.MANAGEMENT_PHARMACOMPANY) && (
        <div className={clsx(classes.update, classes.column)}>
          <div className={classes.padding}>
            <Progress show={isUpdating} />
            {renderUpdateButtons()}
          </div>
        </div>
      )}
    </div>
  );
}

ProductManagementTableRow.propTypes = {
  availability: PropTypes.shape({
    productId: PropTypes.string,
    vnr: PropTypes.string,
    msiCode: PropTypes.string,
    information: PropTypes.string,
    hasPermanentInformation: PropTypes.bool,
    availabilityDate: PropTypes.string,
    availabilityEstimationExpired: PropTypes.bool,
    latestUpdate: PropTypes.shape({
      user: PropTypes.shape({
        name: PropTypes.string,
      }),
      time: PropTypes.string,
    }),
    localChanges: PropTypes.shape({
      information: PropTypes.string,
      availabilityDate: PropTypes.string,
    }),
    productStrength: PropTypes.string,
    profitCenter: PropTypes.string,
  }).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isHiglighted: PropTypes.bool,
  isEditing: PropTypes.bool,
  permission: PropTypes.string,
  price: PropTypes.shape({
    vatRate: PropTypes.string,
  }),
  showAvailabilityLabels: PropTypes.bool,
  showEstimationInWeeks: PropTypes.bool,
  profitCenterName: PropTypes.string,
  marketerName: PropTypes.string,
};
ProductManagementTableRow.defaultProps = {
  isHiglighted: false,
  isEditing: false,
  showAvailabilityLabels: false,
  showEstimationInWeeks: false,
  permission: null,
  price: null,
  profitCenterName: null,
  marketerName: null,
};

export default memo(ProductManagementTableRow);
