// eslint-disable-next-line no-use-before-define
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ProductManagementTableRow from "./productManagementTableRow";
import { InfinityList } from "../generic";
import { Permission } from "../auth";
import { isDateLessThanDayAgo } from "../../utils/date/date";
import styles from "./tableStyles";
import { fetchAllPrices, fetchProfitCenters } from "../../redux/reducers";

export const PRODUCTS_ON_PAGE = 15;
export const MIN_SHOW_PRODUCTS = 10;
const FIXED_ROW_HEIGHT = 80;
const ROW_SPACING = 1;

const useStyles = makeStyles(styles);

function ProductManagementTable({
  title,
  availabilityInfo,
  notifications,
  profitCenterMap,
  customers,
  permission,
  productsWaitingForAction,
  expanded,
  showAvailabilityLabels,
  showEstimationInWeeks,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const count = availabilityInfo.length;
  const itemsToShow = expanded
    ? availabilityInfo
    : availabilityInfo.slice(0, MIN_SHOW_PRODUCTS);
  const [prices, setPrices] = useState([]);
  const dispatch = useDispatch();
  const productKey =
    permission === Permission.MANAGEMENT_PHARMACY
      ? "productManagementTable.pharmacyProduct"
      : "productManagementTable.product";

  if (count < 1) {
    return null;
  }

  const renderFixedHeader = () => (
    <div className={classes.tableHead}>
      <div className={classes.headerRow}>
        <div className={classes.product}>{t(productKey)}</div>
        {permission === Permission.MANAGEMENT_ORIOLA && (
          <div className={classes.principal}>
            {t("productManagementTable.principal")}
          </div>
        )}
        <div className={classes.estimated}>
          {t("productManagementTable.estimatedAvailability")}
        </div>
        {(permission === Permission.MANAGEMENT_ORIOLA ||
          permission === Permission.MANAGEMENT_PHARMACOMPANY) && (
          <div className={classes.availability}>
            {t("productManagementTable.availabilityUpdate")}
          </div>
        )}
        <div className={classes.information}>
          {t("productManagementTable.additionalInformation")}
          {(permission === Permission.MANAGEMENT_ORIOLA ||
            permission === Permission.MANAGEMENT_PHARMACOMPANY) && (
            <div className={classes.informationSpecification}>
              {t("productManagementTable.additionalInformationSpecification")}
            </div>
          )}
        </div>
        <div className={classes.informationPermanent}>
          {t("productManagementTable.permanentAdditionalInformation")}
        </div>
        {permission === Permission.MANAGEMENT_PHARMACY && (
          <div className={classes.notify}>
            {t("productManagementTable.notifyWhenProductInStock")}
          </div>
        )}
        {(permission === Permission.MANAGEMENT_ORIOLA ||
          permission === Permission.MANAGEMENT_PHARMACOMPANY) && (
          <div className={classes.update}>
            {t("productManagementTable.update")}
          </div>
        )}
      </div>
    </div>
  );

  const isHiglighted = availability => {
    if (
      permission === Permission.MANAGEMENT_ORIOLA ||
      permission === Permission.MANAGEMENT_PHARMACOMPANY
    ) {
      return productsWaitingForAction.some(
        a => a.productId === availability.productId
      );
    }

    if (permission === Permission.MANAGEMENT_PHARMACY) {
      return (
        availability.latestUpdate &&
        isDateLessThanDayAgo(availability.latestUpdate.time)
      );
    }
    return false;
  };

  const isEditing = availability => {
    if (
      permission === Permission.MANAGEMENT_ORIOLA ||
      permission === Permission.MANAGEMENT_PHARMACOMPANY
    ) {
      return productsWaitingForAction.some(
        a => a.productId === availability.productId
      );
    }
    return false;
  };

  const rowRenderer = ({ key, style, index }) => {
    const availability = availabilityInfo[index];
    const price = prices.find(p => p.materialId === availability.productId);

    return (
      <div key={`${key}-${availability.productId}`} style={style}>
        <ProductManagementTableRow
          key={`${key}-${availability.productId}`}
          notifications={notifications}
          availability={availability}
          profitCenterName={profitCenterMap.get(availability.profitCenter)}
          marketerName={availability.marketer}
          customers={customers}
          price={price}
          isHiglighted={isHiglighted(availability)}
          isEditing={isEditing(availability)}
          permission={permission}
          showAvailabilityLabels={showAvailabilityLabels}
          showEstimationInWeeks={showEstimationInWeeks}
        />
      </div>
    );
  };

  const onProductsLoad = (startIndex, stopIndex) => {
    const items = itemsToShow.slice(startIndex, stopIndex + 1);
    const materialIds = items.map(item => item.productId);
    dispatch(fetchAllPrices(materialIds)).then(priceData => {
      if (priceData != null) {
        setPrices(priceData);
      }
    });

    if (
      permission === Permission.MANAGEMENT_PHARMACY ||
      permission === Permission.MANAGEMENT_ORIOLA
    ) {
      // fetch profit center names
      const profitCenterIds = items
        .map(item => item.profitCenter)
        .filter(profitCenter => profitCenterMap.get(profitCenter) == null);
      if (profitCenterIds.length) {
        dispatch(fetchProfitCenters(Array.from(new Set(profitCenterIds))));
      }
    }
  };

  return (
    <div className={classes.tableContainer}>
      {title && (
        <Typography variant="subtitle2" className={classes.tableTitle}>
          {title} ({count})
        </Typography>
      )}
      {renderFixedHeader()}
      <InfinityList
        items={itemsToShow}
        visibleItemCount={Math.min(itemsToShow.length, PRODUCTS_ON_PAGE)}
        itemOverallCount={itemsToShow.length}
        rowHeight={FIXED_ROW_HEIGHT}
        rowSpacing={ROW_SPACING}
        onRenderRow={rowRenderer}
        onLoad={(start, stop) => onProductsLoad(start, stop)}
      />
    </div>
  );
}

ProductManagementTable.propTypes = {
  title: PropTypes.string,
  availabilityInfo: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      profitCenter: PropTypes.string,
      marketer: PropTypes.string,
    })
  ).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profitCenterMap: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})),
  permission: PropTypes.string,
  productsWaitingForAction: PropTypes.arrayOf(PropTypes.shape({})),
  expanded: PropTypes.bool,
  showAvailabilityLabels: PropTypes.bool,
  showEstimationInWeeks: PropTypes.bool,
};
ProductManagementTable.defaultProps = {
  title: "",
  customers: [],
  permission: "",
  expanded: false,
  showAvailabilityLabels: false,
  showEstimationInWeeks: false,
  productsWaitingForAction: [],
};

export default memo(ProductManagementTable);
